import React from 'react';
import LandingPage from './pages/LandingPage';
import Navbar from './pages/Navbar';
import ScrollToTop from "./ScrollToTop";




function App() {




    return (

        <>

            <ScrollToTop />
            <Navbar/>
            <LandingPage/>
        </>
    );
}

export default App;
